<template>
  <div id="compare">
    <div class="canvas-spacer lg"></div>
    <div
      class="top-container d-flex text-left p-2 justify-content-between flex-row align-items-center"
    >
      <div>
        <div class="d-flex flex-row align-items-center">
          <h1 class="page-title">Compare</h1>
        </div>
        <div class="description">
          Use the sliders below to adjust the model selection.
        </div>
      </div>
    </div>
    <div
      id="compare-parent-container"
      class="d-flex flex-row justify-content-between"
    >
      <div id="compare-slider-container" class="container low-index-ui">
        <div class="row h-100">
          <div
            class="col-10 col-lg-10 col-sm-10 m-0 p-1 mx-auto d-flex flex-column justify-content-around"
          >
            <div
              class="slider-parent"
              v-for="input in compareSliderObject"
              :key="input.label + '-key'"
            >
              <div class="standard-text label">{{ input.label }}</div>
              <vue-slider
                :title="'adjust ' + input.label"
                v-model="input.value"
                :data="input.data"
                :ref="input.ref"
                @drag-end="setInputValues"
                @error="sliderError"
                :stop-propagation="true"
                v-bind="sliderOptions"
                :marks="input.marks"
                :clickable="false"
                :absorb="true"
                :lazy="true"
                :order="false"
              >
                <template v-slot:dot="{ value, focus, index }">
                  <div
                    class="vue-slider-dot-handle outline-handle"
                    :class="{ 'compare-slider-0': index === 0 }"
                    v-if="index === 0"
                    @mouseenter="highlightScene(index, true)"
                    @mouseout="highlightScene(index, false)"
                  ></div>
                  <div
                    class="vue-slider-dot-handle"
                    :class="{ 'compare-slider-1': index === 1 }"
                    v-if="index === 1"
                    @mouseenter="highlightScene(index, true)"
                    @mouseout="highlightScene(index, false)"
                    @mousedown="highlightScene(index, true)"
                  ></div>
                </template>
              </vue-slider>
            </div>
          </div>
        </div>
      </div>
      <div id="compare-card-container" ref="card-container">
        <canvas id="canvasParent" ref="compareCanvas"></canvas>
        <div
          ref="compareContent"
          class="container-fluid h-100 d-flex align-items-center"
        >
          <div class="row h-75 w-100 d-flex justify-content-evenly">
            <div
              class="col-2 col-lg-2 h-100 d-flex flex-column align-items-center"
            >
              <div class="top-spacer w-100" style="min-height: 285px"></div>
              <div
                class="metric-labels d-flex flex-column justify-content-around h-100 flex-grow-1 text-left"
              >
                <div
                  class="metric-label-container"
                  v-for="(metric, i) in selectedModels['model0']['metricCard']"
                  :key="i"
                >
                  <div class="label">{{ metric.label }}</div>
                  <div class="units-text">{{ metric.units }}</div>
                </div>
              </div>
            </div>
            <div
              class="col-4 col-lg-4 col-md-4 col-sm-12"
              v-for="(model, index) in selectedModels"
              :key="'compare-row-' + index"
            >
              <compareCard
                :modelObject="model"
                @compareCardHightlight="updateCompareCardHighlight"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// vue slider import
import VueSlider from 'vue-slider-component'
// slider template styling
import 'vue-slider-component/theme/default.css'
import { mapGetters } from 'vuex'

// import helper functions
import {
  filterModelData,
  cleanScenes,
  getStarRating,
  getRandomIterationID
} from '@/assets/js/helper.js'

import { renderScenes, createScenes } from '@/assets/js/sceneHelpers.js'

import compareCard from '@/components/compare/compare_card.vue'
const THREE = require('three')
const OrbitControls = require('three-orbit-controls')(THREE)

let renderer = null
let loader = new THREE.ObjectLoader()
let scenes = []
let compareModels = []
let contextOBJ = null

export default {
  name: 'compare',
  components: {
    VueSlider,
    compareCard
  },
  data () {
    return {
      /**
       * @type object
       * slider options
       */
      sliderOptions: {
        dotSize: 14,
        // "marks": true,
        lazy: true,
        process: false,
        tooltip: 'none',
        railStyle: {
          'background-color': 'rgba(253, 203, 204, 0.41)'
        }
      },
      sceneBlack: new THREE.Color('rgb(24,24,24)'),
      selectedModels: {
        model0: {
          index: 0,
          metricCard: {},
          modelID: '1',
          highlightScene: false,
          color: '#1098B2',
          defaultColor: '#5D5D5D',
          sceneIndex: 'compare-scene-index-',
          selectedModel: 0,
          compareStatus: null
        },
        model1: {
          index: 1,
          metricCard: {},
          modelID: '1',
          highlightScene: false,
          color: '#00f2fe',
          defaultColor: '#5D5D5D',
          sceneIndex: 'compare-scene-index-',
          selectedModel: 0,
          compareStatus: null
        }
      }
    }
  },
  created () {
    this.createSliderObject()
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {},
  destroyed () {
    function empty (elem) {
      while (elem.lastChild) elem.removeChild(elem.lastChild)
    }
    empty(this.canvas)

    cleanScenes(scenes)

    renderer.domElement.addEventListener('dblclick', null, false)
    renderer.renderLists.dispose()
    renderer.clear()

    scenes = []
    compareModels = []

    this.content.removeEventListener('scroll', this.render, false)
    window.removeEventListener('resize', this.render, false)
  },
  computed: {
    ...mapGetters({
      inputObject: 'getInputObject',
      inputHeaders: 'getInputHeaders',
      metricObject: 'getMetricObject',
      modelData: 'getModelData',
      numberOfModels: 'getNumberOfModels',
      projectLocation: 'getProjectLocation',
      contextObject: 'getContextObject'
    }),
    canvas () {
      return this.$refs['compareCanvas']
    },
    content () {
      return this.$refs['compareContent']
    },
    getModelIDs () {
      return this.modelKeys.map((key) => this.selectedModels[key]['modelID'])
    },
    modelKeys () {
      return Object.keys(this.selectedModels)
    }
  },
  methods: {
    updateCompareCardHighlight (event) {
      const [index, toggle] = event
      this.highlightScene(index, toggle)

      const handles = document.querySelectorAll('.compare-slider-' + index)

      const classToggle = toggle === true ? 'add' : 'remove'

      handles.forEach((e) => {
        e.classList[classToggle]('active')
      })
    },
    highlightScene (index, toggle) {
      this.selectedModels['model' + index].highlightScene = toggle
    },
    init () {
      // set loading flag on
      this.$store.commit('setLoadingFlag', true)

      this.canvas.classList.add('inactive')

      renderer = new THREE.WebGLRenderer({
        canvas: this.canvas,
        antialias: true
      })

      renderer.setPixelRatio(window.devicePixelRatio)

      this.setInputValues('init')

      contextOBJ = this.contextObject
    },
    removeModelByName (scene, modelName) {
      let selectedObject = scene.getObjectByName('scene-object-' + modelName)

      selectedObject.children.forEach(function (child, i) {
        child.geometry.dispose()
        child.material.dispose()
        child = undefined
      })

      scene.remove(selectedObject)

      selectedObject = undefined

      renderer.dispose()
      renderer.renderLists.dispose()
    },
    updateCompareModels () {
      this.modelKeys.forEach((d, i) => {
        if (
          this.selectedModels[d]['prev_modelID'] !==
          this.selectedModels[d]['modelID']
        ) {
          this.removeModelByName(
            scenes[i],
            this.selectedModels[d]['prev_modelID']
          )
          this.updateSingleScene(scenes[i], this.selectedModels[d]['modelID'])
        }
      })
    },
    async getModelByID (modelID) {
      const response = await this.$store.dispatch('getModelByID', [
        modelID,
        this.projectLocation
      ])

      return response.data.model
    },
    async updateSingleScene (scene, modelID) {
      const d = await this.getModelByID(modelID + '_option.json')
      d['metadata']['ID'] = modelID

      loader.parse(d, (obj) => {
        obj.name = 'scene-object-' + modelID

        let ms = scene.userData.modelSettings

        obj.position.set(ms.x, ms.y * 2, ms.z)

        scene.add(obj)

        this.render()
      })
      // })
    },
    updateScenes () {
      scenes = createScenes(compareModels, scenes, contextOBJ)

      // assign the new modelIDs to the div elements for rendering
      scenes.forEach((e, i) => {
        this.selectedModels['model' + i]['selectedModel'] = scenes[i].name
      })

      this.$nextTick((e) => {
        this.attachScenesToContainer()
      })
    },
    attachScenesToContainer () {
      scenes.forEach((scene) => {
        const ID = 'compare-scene-index-' + scene.name
        let sc = scene.userData
        sc.element = document.getElementById(ID)

        sc.createScene.constructControls(OrbitControls, sc.element)

        // setup controls
        const controls = sc.createScene.controls
        controls.enablePan = false
        controls.target.set(0, sc.modelSettings.y, 0)
        controls.update()
        controls.addEventListener('change', this.render)
      })

      this.updateSceneListeneres()
    },
    updateSceneListeneres () {
      window.addEventListener('resize', this.render, false)

      this.content.addEventListener('scroll', this.render, false)

      this.$store.commit('setLoadingFlag', false)

      this.canvas.classList.remove('inactive')

      this.render()
    },
    onResize () {
      let width = this.canvas.clientWidth

      let height = this.canvas.clientHeight

      if (this.canvas.width !== width || this.canvas.height !== height) {
        renderer.setSize(width, height, false)
      }
    },
    render () {
      this.onResize()

      let el = document.getElementById('compare-card-container').scrollTop

      this.canvas.style.transform = `translateY(${el}px)`

      renderScenes(scenes, renderer)

      // renderer.setClearColor(this.sceneBlack)
      // renderer.setScissorTest(false)
      // renderer.clear()
      // renderer.setClearColor(0xe0e0e0)
      // renderer.setScissorTest(true)

      // scenes.forEach(scene => {
      //   const element = scene.userData.element

      //   // get its position relative to the page's viewport
      //   const rect = element.getBoundingClientRect()

      //   // set the frame
      //   const width = rect.right - rect.left
      //   const height = rect.height
      //   const left = rect.left
      //   const bottom = renderer.domElement.clientHeight - rect.bottom + 57

      //   renderer.setViewport(left, bottom, width, height)
      //   renderer.setScissor(left, bottom, width, height)

      //   // camera.aspect = width / height; // not changing in this example
      //   // camera.updateProjectionMatrix();
      //   // scene.userData.controls.update() // may need if switching to animation frame
      //   renderer.render(scene, scene.userData.camera)
      // })
    },

    createSliderObject () {
      let model1 = this.modelData[getRandomIterationID(this.numberOfModels)]
      let model2 = this.modelData[getRandomIterationID(this.numberOfModels)]

      const inputObjectCopy = JSON.parse(JSON.stringify(this.inputObject))

      Object.keys(inputObjectCopy).forEach((key) => {
        // let v = inputObjectCopy[key].value
        // let d = inputObjectCopy[key].data
        // // inputObjectCopy[key].value = [v, d[ (parseInt(v) +1) ]]
        inputObjectCopy[key].value = [model1[key], model2[key]]
      })

      this.compareSliderObject = inputObjectCopy
    },
    async setInputValues (type) {
      const v1 = {}
      const v2 = {}

      const mockupValues = []

      // get values from each slider element
      Object.keys(this.compareSliderObject).forEach((key, i) => {
        const element = this.compareSliderObject[key]
        v1[element['dataName']] = element['value'][0]
        v2[element['dataName']] = element['value'][1]
      })
      // push temporary values to array
      // order is model0 then model1
      mockupValues.push(v1, v2)

      function compareMetrics (obj1, obj2, key) {
        const compare =
          obj1['metricCard'][key]['prettyValue'] >
          obj2['metricCard'][key]['prettyValue']

        if (compare) {
          obj1['metricCard'][key]['compareStatus'] = true
          obj2['metricCard'][key]['compareStatus'] = false
        } else {
          obj1['metricCard'][key]['compareStatus'] = false
          obj2['metricCard'][key]['compareStatus'] = true
        }
      }

      // filter model data based on inputs
      this.modelKeys.forEach((d, i) => {
        const iteration = mockupValues[i]
        const ID = filterModelData(
          this.modelData,
          this.inputHeaders,
          iteration
        )[0]['iteration']

        let m = Object.assign({}, this.modelData[ID])
        let modelSelect = this.selectedModels[d]['modelID']
        this.selectedModels[d]['prev_modelID'] = modelSelect
        this.selectedModels[d]['modelID'] = ID
        this.selectedModels[d]['metricCard'] = getStarRating(
          m,
          this.metricObject
        )
      })

      Object.keys(this.selectedModels['model0']['metricCard']).forEach((d) => {
        compareMetrics(
          this.selectedModels['model0'],
          this.selectedModels['model1'],
          d
        )
      })

      if (type === 'init') {
        let modelIDs = this.getModelIDs

        const d = await this.getModelByID(modelIDs[0] + '_option.json')
        d['metadata']['ID'] = modelIDs[0]

        compareModels.push(d)

        const d2 = await this.getModelByID(modelIDs[1] + '_option.json')
        d2['metadata']['ID'] = modelIDs[1]

        compareModels.push(d2)

        this.updateScenes()
      } else {
        this.updateCompareModels()
      }
    },
    /**
     * @type error
     * only fires on slider error
     */
    sliderError (type, message) {
      console.error('slider errorr type ', type, message)
    }
  }
}
</script>

<style lang="scss" scoped>
#compare {
  position: relative;
  // top: $navbar-height;

  height: calc(100vh - #{$navbar-height});
  padding: 0 2rem;

  overflow: hidden;
}

$compare-container-width: 500px;
$compare-container-height: calc(
  100vh - #{$navbar-height} - #{$top-container-height}
);

#compare-slider-container {
  width: $compare-container-width;
  height: $compare-container-height;
}
#compare-card-container {
  width: calc(100vw - #{$compare-container-width});
  height: $compare-container-height;
}
</style>
